import React from 'react';
import { Container } from 'react-bootstrap';
import TitleWithBg from './TitleWithBg';

export default function HomeLogos() {
  const logoName = [];
  logoName.length = 30;
  logoName.fill(1);
  return (
    <section id="logos" className="home-logos">
      <Container>
        <TitleWithBg title="帮助众多TOB企业提升数字化营销能力" />
        <div className="logos">
          {logoName.map((item, index) => (
            <div key={`co-logo-${item + index}`} className={`logo co-logo-${index + 1}`} />
          ))}
        </div>
      </Container>
    </section>
  );
}
