import React from 'react';
import { Container, Row, Col, Image, Pagination } from 'react-bootstrap';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner2 from '../components/Banner2';
import { useSearch } from '../utils/common';
import HomeLogos from '../components/HomeLogos';
import BlogsBannerImage from '../images/blogs-banner-right.svg';
import BannerImage from '../images/cases-banner-2.svg';

function Article({ title, author, date, cover, description, path }) {
  return (
    <Row className="article">
      <Col lg={{ span: 5 }} xs={{ span: 12 }}>
        <Image
          alt={title}
          src={
            cover ||
            'https://cltemplate.oss-cn-hangzhou.aliyuncs.com/template/jike/WX20220113-195216%402x.png'
          }
          className="img-fluid"
        />
      </Col>
      <Col className="articleDesc" lg={{ span: 7 }} xs={{ span: 12 }}>
        <div className="content">
          <div className="title">
            <a target="_blank" href={path} rel="noreferrer">
              {title}
            </a>
          </div>
          <div className="description">{description}</div>
        </div>
        <div className="bottomSection">
          <div className="left">
            <div className="author">作者：{author}</div>
            <div className="date">{date}</div>
          </div>
          <div className="right">
            <a target="_blank" href={path} rel="noreferrer">
              查看全文 &gt;
            </a>
          </div>
        </div>
      </Col>
    </Row>
  );
}

const BannerRight = () => {
  return (
    <div className="banner-right-container">
      <img src={BlogsBannerImage} alt="about-us" />
    </div>
  );
};

export default ({ data, pageContext }) => {
  const search = useSearch();
  const { edges, pageInfo } = data.allMdx;
  const Items = [];
  const hrefTag = pageContext.pageType + 's';

  for (let number = 1; number <= pageInfo.pageCount; number += 1) {
    Items.push(
      <Pagination.Item
        key={number}
        active={number === pageInfo.currentPage}
        href={number === 1 ? `/${hrefTag}/${search}` : `/${hrefTag}/${number}/${search}`}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Layout current={hrefTag}>
      <Banner2
        title="荟聚B2B干货知识"
        titleClassName="blogs-title"
        Ptitle3="营销人的学堂，市场人的资料库"
        ptitleClassName="blogs-title-description"
        backgroundImage={BannerImage}
        rightRender={<BannerRight />}
      />
      <section className="resources">
        <Container>
          {edges.map(({ node }) => (
            <Article
              key={node.id}
              {...node.frontmatter}
              path={`/${node.frontmatter.type}/${node.slug}/${search}`}
            />
          ))}
          {pageInfo.pageCount > 1 && (
            <div className="pagiContainer">
              <Pagination>{Items}</Pagination>
            </div>
          )}
        </Container>
      </section>
      <HomeLogos />
    </Layout>
  );
};

export const blogListQuery = graphql`
  query blogListQuery($skip: Int, $limit: Int, $pageType: String) {
    allMdx(
      filter: { frontmatter: { type: { eq: $pageType }, listHidden: { ne: "yes" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            author
            date
            cover
            description
            type
          }
        }
      }
      totalCount
      pageInfo {
        currentPage
        perPage
        pageCount
      }
    }
  }
`;
